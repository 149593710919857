import { useTranslation } from 'react-i18next';
import { ProductCardTypeEnum } from 'src/ts-types';
import { ProductCardType } from 'src/ts-types/custom.types';

type Props = {
  updateProductType: (e: any, val: ProductCardType) => void;
  selectedType: string;
};

const productCards = [
  {
    label: 'Helium',
    value: 'helium',
    img: '/image/helium-card.png',
    type: ProductCardTypeEnum.HELIUM,
  },
  {
    label: 'Neon',
    value: 'neon',
    img: '/image/neon-card.png',
    type: ProductCardTypeEnum.NEON,
  },
  {
    label: 'Argon',
    value: 'argon',
    img: '/image/argon-card.png',
    type: ProductCardTypeEnum.ARGON,
  },
  {
    label: 'Krypton',
    value: 'krypton',
    img: '/image/krypton-card.png',
    type: ProductCardTypeEnum.KRYPTON,
  },
  // {
  //   label: 'Xenon',
  //   value: 'xenon',
  //   img: '/image/card-xenon.png',
  // },
  {
    label: 'Radon',
    value: 'radon',
    img: '/image/card-radon.png',
    type: ProductCardTypeEnum.RADON,
  },
];

const SiteProductType: React.FC<Props> = ({ updateProductType, selectedType }: Props) => {
  const { t }: any = useTranslation<any>();

  return (
    <div className='grid sm:grid-cols-2 md:grid-cols-4 gap-4 content-evenly animate-in slide-in-from-top mt-10'>
      {productCards?.map((productCard, index) => (
        <div
          className={`flex block p-6 rounded-lg shadow-lg bg-white md:w-60 place-items-center cursor-pointer 
               ${
                 selectedType === productCard.value
                   ? 'border-accent border-2'
                   : ' border border-border-100 hover:border-accent '
               }`}
          key={index}
          onClick={(e) => updateProductType(e, productCard.type)}
        >
          <div className='m-auto self-center'>
            <div className='flex flex-col justify-center items-center pt-2'>
              <h5 className='text-gray-900 text-xl leading-tight font-medium mb-2 text-center'>
                {t(productCard.label)}
              </h5>
            </div>
            <div className='p-3 pb-0 w-full max-h-72 flex items-center justify-center overflow-hidden'>
              <img
                src={productCard.img ?? '/product-placeholder-borderless.svg'}
                alt={productCard.label}
                className='object-contain'
              />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default SiteProductType;
