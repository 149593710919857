export const API_ENDPOINTS_NEW_VARIABLES = {
  SHOP_ID: 'shopId',
  PACKAGE_ID: 'packageId',
  CATEGORY_ID: 'categoryId',
  BRAND_ID: 'brandId',
  PRODUCT_ID: 'productId',
  SALE_ID: 'saleId',
  ORDER_ID: 'orderId',
  COUPON_NAME: 'couponName',
  PLUGIN_ID: 'pluginId',
  USER_ID: 'userId',
};

export const shopAPIPrefix = 'shop/v1';
export const packageAPIPrefix = 'shop/v1/package';
export const authAPIPrefix = 'auth/v1';
export const productAPIPrefix = 'product/v1';
export const orderAPIPrefix = 'order/v1';
export const domainAPIPrefix = 'shop/v1/domain';
export const userAPIPrefix = 'user/v1';

export const API_ENDPOINTS_NEW = {
  // Auth APIs
  ADD_DEVICE_TOKEN: `${authAPIPrefix}/addDeviceToken`,
  LOGIN_BY_MOBILE: `${authAPIPrefix}/loginByMobile`,
  LOGIN_BY_EMAIL: `${authAPIPrefix}/loginByEmail`,
  GOOGLE_LOG_IN: `${authAPIPrefix}/loginByGoogle`,
  REFRESH_TOKEN: `${authAPIPrefix}/refreshtoken`,
  REGISTER: `${authAPIPrefix}/registerByEmail`,
  PRINT_ORDER: {
    prefix: `${orderAPIPrefix}/`,
    id: API_ENDPOINTS_NEW_VARIABLES.ORDER_ID,
    suffix: '/pdf',
  },
  ADD_NEW_EMPLOYEE: `${authAPIPrefix}/add-employee-to-shop`,
  GET_ALL_EMPLOYEE: `${authAPIPrefix}/get-all-employees`,
  GET_OTP: `${authAPIPrefix}/otp/sendVerificationCode`,
  VALIDATE_OTP: `${authAPIPrefix}/otp/validate`,
  DELETE_EMPLOYEE: {
    prefix: `${authAPIPrefix}/`,
    id: API_ENDPOINTS_NEW_VARIABLES.USER_ID,
    suffix: '/remove-employee-from-shop',
  },

  GET_USER_DETAILS: {
    prefix: 'user/v1/',
    id: API_ENDPOINTS_NEW_VARIABLES.USER_ID,
    suffix: '/getUserDetails',
  },

  // Shop APIs
  ALL_MY_ALERTS: `${shopAPIPrefix}/notification/getMyAlerts`,
  MARK_ALERT_AS_SEEN: `${shopAPIPrefix}/notification/markAlertsAsSeen`,
  SHOPS: `${shopAPIPrefix}/getAllShops`,
  GET_ALL_MY_SHOPS: `${shopAPIPrefix}/getAllMyShops`,
  CREATE_SHOP: `${shopAPIPrefix}/order/buyPurchasePackage`,
  REGISTER_DOMAIN: `${shopAPIPrefix}/addNewDomainDomain`,
  CREATE_ANONYMOUS_SHOP: `${shopAPIPrefix}/order/startWithoutPackage`,
  UPDATE_SHOP_SETTINGS: `${shopAPIPrefix}/updateShopSettings`,
  SEND_NOTIFICATION: `${shopAPIPrefix}/sendNotification`,
  CONTACT_US: `${shopAPIPrefix}/send-to-contact-us`,
  SHOP_SETTINGS: {
    prefix: `${shopAPIPrefix}/`,
    id: API_ENDPOINTS_NEW_VARIABLES.SHOP_ID,
    suffix: '/settings',
  },
  PLANS: `${shopAPIPrefix}/package/getAllPackagesToPurchase`,
  GET_SHOP_DETAILS: `${shopAPIPrefix}/getShopDetails`,
  DELETE_SHOP: `${shopAPIPrefix}/cancelShop`,
  UPDATE_SHOP_PRIVATE_DETAILS: `${shopAPIPrefix}/updateShopPrivateDetails`,
  UPDATE_SHOP_PLUGIN: `${shopAPIPrefix}/updateShopPlugIn`,
  UPDATE_PACKAGE: {
    prefix: `${packageAPIPrefix}/`,
    id: API_ENDPOINTS_NEW_VARIABLES.PACKAGE_ID,
    suffix: '/updatePurchasePackage',
  },
  GET_PACKAGE_BY_ID: {
    prefix: `${packageAPIPrefix}/`,
    id: API_ENDPOINTS_NEW_VARIABLES.PACKAGE_ID,
    suffix: '/getPurchasePackageById',
  },
  CREATE_PACKAGE: `${shopAPIPrefix}/package/addNewPurchasePackage`,
  DELETE_PLUGINS_BY_ID: {
    prefix: `${shopAPIPrefix}/plugin/`,
    id: API_ENDPOINTS_NEW_VARIABLES.PLUGIN_ID,
    suffix: '/removePlugin',
  },
  GET_PLUGINS: `${shopAPIPrefix}/getAllPlugins`,
  GET_PLUGINS_BY_ID: {
    prefix: `${shopAPIPrefix}/plugin/`,
    id: API_ENDPOINTS_NEW_VARIABLES.PLUGIN_ID,
    suffix: '/getPlugInById',
  },
  UPDATE_PLUGINS_BY_ID: {
    prefix: `${shopAPIPrefix}/plugin/`,
    id: API_ENDPOINTS_NEW_VARIABLES.PLUGIN_ID,
    suffix: '/updatePlugIn',
  },
  ADD_NEW_PLUGIN: `${shopAPIPrefix}/plugin/addNewPlugin`,
  UPDATE_CONTACT_US: `${shopAPIPrefix}/update-contact-us`,
  CREATE_CONTACT_US: `${shopAPIPrefix}/create-contact-us`,
  GET_CONTACT_US: `${shopAPIPrefix}/get-contact-us`,

  // Product APIs
  GET_PRODUCTS: `${productAPIPrefix}/getAllProductsByAdmin`,
  GET_CATEGORIES: `${productAPIPrefix}/retrieveAllCategoriesByAdmin`,
  GET_BRANDS: `${productAPIPrefix}/retrieveAllBrandsByAdmin`,
  ADD_BRAND: `${productAPIPrefix}/brand/addNewBrand`,
  UPDATE_BRAND_BY_ID: {
    prefix: `${productAPIPrefix}/brand/`,
    id: API_ENDPOINTS_NEW_VARIABLES.BRAND_ID,
    suffix: '/updateBrand',
  },
  ADD_CATEGORY: `${productAPIPrefix}/category/addNewCategory`,
  UPDATE_CATEGORY: {
    prefix: `${productAPIPrefix}/category/`,
    id: API_ENDPOINTS_NEW_VARIABLES.CATEGORY_ID,
    suffix: '/updateCategory',
  },
  DELETE_CATEGORY: {
    prefix: `${productAPIPrefix}/category/`,
    id: API_ENDPOINTS_NEW_VARIABLES.CATEGORY_ID,
    suffix: '/delete-category',
  },
  GET_ADDONS_CATEGORIES: `${productAPIPrefix}/add-on/retrieve-all-add-ons-categories`,
  ADD_ADDONS: `${productAPIPrefix}/add-on/add-new-add-on`,
  UPDATE_ADDONS_CATEGORY: `${productAPIPrefix}/add-on/update-add-ons-categories`,
  CREATE_ADDONS_CATEGORY: `${productAPIPrefix}/add-on/add-new-add-on`,
  UPDATE_ADDONS: `${productAPIPrefix}/add-on/update-add-on-details`,
  DELETE_ADDONS: `${productAPIPrefix}/add-on/remove-add-from-addons-category`,
  DELETE_ADDONS_CATEGORY: `${productAPIPrefix}/add-on/remove-addon-category`,
  DELETE_BRAND: {
    prefix: `${productAPIPrefix}/brand/`,
    id: API_ENDPOINTS_NEW_VARIABLES.BRAND_ID,
    suffix: '/delete-brand',
  },
  ADD_PRODUCT: `${productAPIPrefix}/addNewProduct`,
  UPDATE_PRODUCT: `${productAPIPrefix}/updateProduct`,
  DELETE_PRODUCT: {
    prefix: `${productAPIPrefix}/`,
    id: API_ENDPOINTS_NEW_VARIABLES.PRODUCT_ID,
    suffix: '/removeProduct',
  },
  ADD_NEW_LIST_OF_PRODUCTS: `${productAPIPrefix}/addNewListOfProducts`,

  // Order APIs
  GET_ORDERS: `${orderAPIPrefix}/retrieveAllOrders`,
  REFUND_ORDER: `${orderAPIPrefix}/refundPaymentForOrder`,
  SEARCH_USER_ORDERS: `${orderAPIPrefix}/searchUserOrders`,
  UPDATE_ORDER_BY_ADMIN: `${orderAPIPrefix}/updateOrderByAdmin`,
  GET_SHOP_ANALYTICS: `${shopAPIPrefix}/analytics`,
  GET_SHIPPING_AD_WITH_RULES: `${orderAPIPrefix}/getShippingAdWithRulesForAmin`,
  SET_SHIPPING_AD_WITH_RULES: `${orderAPIPrefix}/setShippingPriceWithRules`,
  GET_SHOP_ID_BY_ORDER_ID: `${orderAPIPrefix}/getShopIdByOrderId`,
  UPDATE_SHIPPING_AD_WITH_RULES: `${orderAPIPrefix}/updateShippingPriceWithRules`,
  GET_ALL_SALES_FOR_ADMIN: `${orderAPIPrefix}/sale/getAllSalesForAdmin`,
  FETCH_ORDER_BY_ID: {
    prefix: `${orderAPIPrefix}/`,
    id: API_ENDPOINTS_NEW_VARIABLES.ORDER_ID,
    suffix: '/getOrderDetailsById',
  },
  UPDATE_SALE: {
    prefix: `${orderAPIPrefix}/sale/`,
    id: API_ENDPOINTS_NEW_VARIABLES.SALE_ID,
    suffix: '/updateSale',
  },
  DELETE_SALE: {
    prefix: `${orderAPIPrefix}/sale/`,
    id: API_ENDPOINTS_NEW_VARIABLES.SALE_ID,
    suffix: '/removeSale',
  },
  CREATE_SALE: `${orderAPIPrefix}/sale/createNewSale`,
  GET_ALL_COUPON: `${orderAPIPrefix}/coupon/getAllCoupons`,
  UPDATE_COUPON: {
    prefix: `${orderAPIPrefix}/coupon/`,
    couponName: API_ENDPOINTS_NEW_VARIABLES.COUPON_NAME,
    suffix: '/updateCouponDetails',
  },
  DELETE_COUPON: {
    prefix: `${orderAPIPrefix}/coupon/`,
    couponName: API_ENDPOINTS_NEW_VARIABLES.COUPON_NAME,
    suffix: '/removeCoupon',
  },
  ADD_COUPON: `${orderAPIPrefix}/coupon/addNewCoupon`,
  // domain
  VALIDATE_DOMAIN: `${domainAPIPrefix}/validateDomain`,
  UPDATE_SHOP_PAYMENT_SETTINGS: `${shopAPIPrefix}/updateShopPaymentDetails`,
  UPDATE_SHOP_APPEARANCE_INFO: `${shopAPIPrefix}/updateShopAppearance`,
  UPDATE_SHOP_MEDIA_INFO: `${shopAPIPrefix}/updateShopMedia`,
  UPDATE_SHOP_POLICY_INFO: `${shopAPIPrefix}/updateShopPolicy`,
  UPDATE_SHOP_PREFERENCES_INFO: `${shopAPIPrefix}/updateShopPreferences`,
  UPDATE_SHOP_BUSINESS_DETAILS_INFO: `${shopAPIPrefix}/updateBusinessDetails`,
};
