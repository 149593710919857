import { useEffect, useState } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { logPageView } from 'src/ga';
import { ROUTES } from 'src/utils/routes';
import { hasAccess, getAuthCredentials } from 'src/utils/authUtils';

const PrivateRoute = ({ component: Component, itemPermissions, ...rest }: any) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isAuthorized, setIsAuthorized] = useState(false);

  useEffect(() => {
    logPageView();

    // Ensure authentication data is loaded before rendering
    const authData = getAuthCredentials();
    const hasToken = !!authData.token;
    const hasPermission = hasAccess(itemPermissions);

    setIsAuthenticated(hasToken);
    setIsAuthorized(hasPermission);
    setIsLoading(false);
  }, [itemPermissions]);

  if (isLoading) {
    return <></>;
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? (
          isAuthorized ? (
            <Component {...props} />
          ) : (
            <Redirect to={ROUTES.UNAUTHORIZED} />
          )
        ) : (
          <Redirect to={ROUTES.LOGIN} />
        )
      }
    />
  );
};

export default PrivateRoute;
