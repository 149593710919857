import { useTranslation } from 'react-i18next';
import Notification from '../ui/notification';

const ContactSupportAlert = () => {
  const { t }: any = useTranslation<any>();

  return (
    <Notification variant='info'>
      <>
        {t('contact_support')}
        <a
          href='https://api.whatsapp.com/send?phone=+9720524769749&text=הי נגו,  '
          target='_blank'
          rel='noreferrer'
          className='text-blue-600 text-lg
         cursor-pointer underline font-bold py-1.5 mr-1 text-center inline-flex items-center'
        >
          {t('contact_support_button')}
        </a>
      </>
    </Notification>
  );
};

export default ContactSupportAlert;
