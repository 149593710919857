import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { withPermission } from 'src/HOC/withPermission';
import { isSuperAdmin } from 'src/utils/authUtils';
import BusinessDetails from './businessDetails';
import ShopSettingsDetails from './shopSettingsDetails';
import ShopAppearance from './shopAppearance';
import ShopMediaDetails from './shopMediaDetails';
import ContactSupportAlert from 'src/components/shop/contactSupportAlert';
import Tabs from './tabs';

const TemplatePage = () => {
  const { t }: any = useTranslation<any>();
  const [step, setStep] = useState<number>(0);
  const isSuperAdminUser = isSuperAdmin();

  return (
    <>
      <Tabs step={step} setStep={setStep} />
      {/* Gray out content if the user is a super admin */}
      <div className='g:mt-18 md:mt-20 smx:mt-32'>
        <div>
          <ContactSupportAlert />
        </div>
        <div className={`${!isSuperAdminUser ? 'opacity-50 pointer-events-none' : ''}`}>
          {step === 0 && <BusinessDetails />}
          {step === 1 && <ShopAppearance />}
          {step === 2 && <ShopMediaDetails />}
          {step === 3 && <ShopSettingsDetails />}
          {step === 4 && <></>}
        </div>
      </div>
    </>
  );
};

export default withPermission(TemplatePage);
