import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useAppSelector, useAppDispatch } from 'src/utils/reduxHooks';
import {
  ImageType,
  Promotion,
  ShopDetailsType,
  ShopMediaType,
  TranslationObject,
} from 'src/ts-types/custom.types';
import Card from 'src/components/common/card';
import { getActiveShopDetails, updateShopMediaDetails } from 'src/store/shop/shopsAPIActions';
import { toast } from 'react-toastify';
import { TOAST_MESSAGE } from 'src/utils/constants';
import Loader from 'src/components/ui/loader/loader';
import TextAreaWithTranslation from 'src/components/ui/text-areaWithTranslation';
import UnControlledFileInput from 'src/components/ui/unControlled-file-input';
import { UPLOAD_SECTION } from 'src/ts-types';
import DynamicInputList from 'src/components/ui/dynamicInputList';
import Button from 'src/components/ui/button';

const ShopMediaDetails = () => {
  const { t }: any = useTranslation<any>();
  const activeShopDetails = useAppSelector(
    (state) => state.shop.shopDetails?.activeShopDetailsResponse?.data,
  ) as ShopDetailsType;
  const { logoUrl, siteImages, shopDescription, promotionSection } = activeShopDetails;
  const dispatch = useAppDispatch();

  const { siteMainImages, siteMainPageImages } = siteImages ?? {};
  const [loading, setLoading] = useState<boolean>(false);
  const [logo, setLogo] = useState<string>(logoUrl || '');
  const [mainImages, setMainImages] = useState<any[]>(siteMainImages ?? ['']);
  const [pageImages, setPageImages] = useState<any>(siteMainPageImages?.[0] ?? '');
  const [aboutUsImage, setAboutUsImage] = useState<any>(siteMainPageImages?.[1] ?? '');

  const [promotionImages, setPromotionImages] = useState(
    promotionSection?.promotions?.map((promotion: Promotion) => promotion.image) ?? [''],
  );
  const [promotionActions, setPromotionsActionsValues] = useState(
    promotionSection?.promotions?.map((promotion: Promotion) => promotion.action) ?? [''],
  );
  const [aboutUsDescription, setAboutUsDescription] = useState<TranslationObject[]>(
    shopDescription ?? [],
  );
  const [promotionDescription, setPromotionDescription] = useState<TranslationObject[]>(
    promotionSection?.description ?? [],
  );
  const filteredPromotionActions = (promotionActions ?? ['']).filter(
    (action) => action !== undefined,
  );

  const { handleSubmit } = useForm<ShopMediaType>({
    mode: 'onBlur',
  });

  const handleLogoUrlUpload = (val: string) => {
    setLogo(val.length === 0 ? '' : val);
  };

  const handelMainImageUpload = (val: ImageType[]) => {
    const images: string[] = [];
    for (let index = 0; index < val.length; index++) {
      images.push(val[index].imageUrl);
    }
    setMainImages(images);
  };

  const handelAboutUsImageUpload = (val: string) => {
    setAboutUsImage(val);
  };

  const handelMainPageImageUpload = (val: string) => {
    setPageImages(val);
  };

  const handelPromotionImageUpload = (val: string | ImageType[]) => {
    const images: string[] = [];
    for (let index = 0; index < val.length; index++) {
      images.push((val[index] as ImageType).imageUrl);
    }
    setPromotionImages(images);
  };

  // Callback to handle updates from the child component
  const handleInputListUpdate = (updatedInputs: string[]) => {
    setPromotionsActionsValues(updatedInputs);
  };

  const onSubmit = () => {
    setLoading(true);
    const promotionObject = promotionImages?.map((img, index) => ({
      image: img,
      action: promotionActions[index] ?? '',
    }));
    const updatedValues: ShopMediaType = {
      siteImages: {
        siteMainImages: mainImages,
        siteMainPageImages: [pageImages].concat(aboutUsImage),
      },
      logoUrl: logo,
      shopDescription: aboutUsDescription,
      promotionSection: {
        promotions: promotionObject,
        description: promotionDescription,
      },
    };
    dispatch(updateShopMediaDetails({ ...updatedValues }))
      .then(() => {
        toast.success(t(TOAST_MESSAGE.COMPANY_INFO_UPDATED_SUCCESSFULLY));
        dispatch(getActiveShopDetails());
        setLoading(false);
      })
      .catch((err: any) => {
        if (err.response.data.status === 401) {
          toast.error(t(TOAST_MESSAGE.UNAUTHORIZED));
        } else {
          toast.error(t(TOAST_MESSAGE.ERROR_TOAST));
        }
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {loading && (
        <div className='h-16 flex items-center mt-2 ms-2'>
          <Loader simple={true} className='w-6 h-6' />
        </div>
      )}
      <Card
        className='flex flex-col mb-8'
        title={t('SITE_IMAGES')}
        titleClassName='text-lg font-semibold text-heading'
      >
        <div className='flex flex-col md:flex-row pt-[12px]'>
          <div className='w-full mx-2 flex-1 svelte-1l8159u '>
            <UnControlledFileInput
              withCrop
              croppedMaxWidth={80}
              croppedMaxHeight={80}
              multiple={false}
              isCircle
              title={t('text-logo')}
              onImageUploaded={(val: string) => handleLogoUrlUpload(val)}
              defaultValue={logo}
              uploadSection={UPLOAD_SECTION.SITE_LOGO}
            />
          </div>
          <div className='w-full mx-2 flex-1 svelte-1l8159u '>
            <UnControlledFileInput
              withCrop
              multiple={true}
              title={t('main_site_image')}
              croppedMaxWidth={150}
              croppedMaxHeight={100}
              onImageUploaded={(val: ImageType[]) => handelMainImageUpload(val)}
              defaultValue={mainImages}
              uploadSection={UPLOAD_SECTION.SITE_MAIN_IMAGE}
            />
          </div>
        </div>
      </Card>
      <Card
        className='flex flex-col mb-8'
        title={t('SITE_ABOUT_US_SECTION')}
        titleClassName='text-lg font-semibold text-heading'
      >
        <div className='flex flex-col md:flex-row'>
          <TextAreaWithTranslation
            label={`${t('input-label-description')}`}
            values={aboutUsDescription}
            setValue={(val: any) => setAboutUsDescription(val)}
            variant='outline'
            className='mb-5'
            name='description'
          />
        </div>
        <div className='w-full mx-2 flex-1 svelte-1l8159u '>
          <UnControlledFileInput
            withCrop
            multiple={false}
            title={t('main_site_image_about_us')}
            croppedMaxWidth={150}
            croppedMaxHeight={100}
            // @ts-ignore
            onImageUploaded={(val: ImageType[]) => handelAboutUsImageUpload(val)}
            defaultValue={aboutUsImage}
            uploadSection={UPLOAD_SECTION.SITE_MAIN_IMAGE}
          />
        </div>
      </Card>
      <Card
        className='w-full'
        title={<h1 className='text-lg font-semibold text-heading'>{t('ADDS_SECTION')}</h1>}
      >
        <div className='w-full mx-2 flex-1 svelte-1l8159u '>
          <UnControlledFileInput
            withCrop
            multiple={false}
            title={t('ADDS_SECTION')}
            croppedMaxWidth={150}
            croppedMaxHeight={100}
            // @ts-ignore
            onImageUploaded={(val: string) => handelMainPageImageUpload(val)}
            defaultValue={pageImages}
            uploadSection={UPLOAD_SECTION.SITE_MAIN_IMAGE}
          />
        </div>
      </Card>
      <Card
        className='w-full'
        title={<h1 className='text-lg font-semibold text-heading'>{t('PROMOTION_SECTION')}</h1>}
      >
        <div className='flex flex-col md:flex-row'>
          <TextAreaWithTranslation
            label={`${t('input-label-description')}`}
            values={promotionDescription}
            setValue={(val: any) => setPromotionDescription(val)}
            variant='outline'
            className='mb-5'
            name='description'
          />
        </div>
        <div className='w-full mx-2 flex-1 svelte-1l8159u '>
          <UnControlledFileInput
            withCrop
            multiple={true}
            title={t('PROMOTION_SECTION')}
            croppedMaxWidth={150}
            croppedMaxHeight={100}
            onImageUploaded={(val: ImageType[]) => handelPromotionImageUpload(val)}
            defaultValue={promotionImages}
            uploadSection={UPLOAD_SECTION.SITE_MAIN_IMAGE}
          />
        </div>
        <DynamicInputList
          promotionsActions={filteredPromotionActions}
          onInputListChange={handleInputListUpdate}
        />
        <Button type='submit' className='w-full ml-5 mt-5'>
          {t('button-label-update')}
        </Button>
      </Card>
    </form>
  );
};

export default ShopMediaDetails;
