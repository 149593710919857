import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useAppSelector, useAppDispatch } from 'src/utils/reduxHooks';
import {
  LayoutType,
  ProductCardType,
  ShopAppearanceType,
  ShopDetailsType,
} from 'src/ts-types/custom.types';
import Card from 'src/components/common/card';
import { RootState } from 'src/store';
import Button from 'src/components/ui/button';
import { getActiveShopDetails, updateShopAppearanceDetails } from 'src/store/shop/shopsAPIActions';
import { toast } from 'react-toastify';
import { TOAST_MESSAGE } from 'src/utils/constants';
import Loader from 'src/components/ui/loader/loader';
import SiteTemplates from 'src/components/createShop/siteTemplates';
import SiteProductType from 'src/components/createShop/siteProductType';
import { ProductCardTypeEnum } from 'src/ts-types';
import Label from 'src/components/ui/label';
import ColorPicker from 'src/components/colorPicker';

const presetColors = ['#6231af', '#db2777', '#f59e0b', '#2dd4bf', '#06b6d4', '#10b981'];

const ShopAppearance = () => {
  const { t }: any = useTranslation();
  const activeShop = useAppSelector(
    (state: RootState) => state.shop.shopDetails?.activeShopDetailsResponse.data,
  ) as ShopDetailsType;
  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState<boolean>(false);
  const {
    primaryColor,
    layoutType,
    productCardType,
    siteBackground,
    secondaryColor,
    footerColor,
    headerColor,
    tertiaryColor,
    siteFontFamily,
  } = activeShop;
  const [siteType, setSiteType] = useState<LayoutType>(layoutType || LayoutType.default);
  const [productType, setProductType] = useState<string>(
    productCardType || ProductCardTypeEnum.HELIUM,
  );
  const [sitePrimaryColor, setSitePrimaryColor] = useState<string>(primaryColor || '#23AD92');
  const [siteSecondaryColor, setSiteSecondaryColor] = useState<string>(secondaryColor || '#23AD92');

  const { handleSubmit, setValue } = useForm<ShopAppearanceType>({
    mode: 'onBlur',
    defaultValues: {
      layoutType: layoutType,
      productCardType: productCardType,
      headerColor: headerColor,
      footerColor: footerColor,
      siteBackground: siteBackground,
      primaryColor: primaryColor,
      secondaryColor: secondaryColor,
      tertiaryColor: tertiaryColor,
      siteFontFamily: siteFontFamily,
    },
  });

  const handleSelect = (e: any, value: LayoutType) => {
    setSiteType(value);
    setValue('layoutType', value);
    e.preventDefault();
  };

  const handleProductSelect = (e: any, value: ProductCardType) => {
    e.preventDefault();
    setValue('productCardType', value);
    setProductType(value);
  };

  const handlePrimaryColorChange = (val: any) => {
    setValue('primaryColor', val);
    setSitePrimaryColor(val);
  };

  const handleSecondaryColorChange = (val: any) => {
    setValue('secondaryColor', val);
    setSiteSecondaryColor(val);
  };

  const onSubmit = (values: ShopAppearanceType) => {
    setLoading(true);
    dispatch(updateShopAppearanceDetails({ ...values }))
      .then(() => {
        toast.success(t(TOAST_MESSAGE.COMPANY_INFO_UPDATED_SUCCESSFULLY));
        dispatch(getActiveShopDetails());
        setLoading(false);
      })
      .catch((err: any) => {
        if (err.response.data.status === 401) {
          toast.error(t(TOAST_MESSAGE.UNAUTHORIZED));
        } else {
          toast.error(t(TOAST_MESSAGE.ERROR_TOAST));
        }
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {loading && (
        <div className='h-16 flex items-center mt-2 ms-2'>
          <Loader simple={true} className='w-6 h-6' />
        </div>
      )}
      <Card
        className='flex flex-col mb-4'
        title={t('SITE_LAYOUT')}
        titleClassName='text-lg font-semibold text-heading'
      >
        <div className='flex flex-col md:flex-row mt-2'>
          <div className='svelte-1l8159 pb-4'>
            <Label>{t('primary-color')}</Label>
            <ColorPicker
              color={sitePrimaryColor}
              onChange={handlePrimaryColorChange}
              presetColors={presetColors}
            />
          </div>
          <div className='svelte-1l8159pb-4 md:pr-8'>
            <Label>{t('secondary-color')}</Label>
            <ColorPicker
              color={siteSecondaryColor}
              onChange={handleSecondaryColorChange}
              presetColors={presetColors}
            />
          </div>
        </div>

        <SiteTemplates selectedType={siteType} updateSiteTemplateType={handleSelect} />
      </Card>
      <Card
        title={t('PRODUCT_LAYOUT')}
        className='flex flex-col mb-4'
        titleClassName='text-lg font-semibold text-heading'
      >
        <SiteProductType
          updateProductType={handleProductSelect}
          selectedType={productType ?? ProductCardTypeEnum.HELIUM}
        />
        <Button type='submit' className='ml-5 mt-5'>
          {t('button-label-update')}
        </Button>
      </Card>
    </form>
  );
};

export default ShopAppearance;
