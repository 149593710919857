export const LIMIT = 10;
export const SUPER_ADMIN = 'SUPPER_ADMIN';
export const EMPLOYEE = 'EMPLOYEE';
export const STORE_OWNER = 'ADMIN';
export const STAFF = 'staff';
export const TOKEN = 'token';
export const PERMISSIONS = 'permissions';
export const AUTH_CRED = 'AUTH_CRED';
export const REDUX_ROOT = 'persist:root';
export const SHOP = 'SHOP';
export const SHOP_CURRENT_STATUS = 'SHOP_CURRENT_STATUS';
export const SHOP_ID = 'SHOP_ID';
export const LOCALE = 'locale';

export const TOAST_MESSAGE = {
  ERROR_TOAST: 'ERROR_TOAST',
  SUCCESS_TOAST: 'SUCCESS_TOAST',
  COMPANY_INFO_UPDATED_SUCCESSFULLY: 'COMPANY_INFO_UPDATED_SUCCESSFULLY',
  PACKAGE_INFO_UPDATED_SUCCESSFULLY: 'PACKAGE_INFO_UPDATED_SUCCESSFULLY',
  PACKAGE_INFO_UPDATED_FAILED: 'PACKAGE_INFO_UPDATED_FAILED',
  PACKAGE_CREATED_SUCCESSFULLY: 'PACKAGE_CREATED_SUCCESSFULLY',
  PACKAGE_FAILED_TO_CREATE: 'PACKAGE_FAILED_TO_CREATE',
  CONTACT_INFO_UPDATED: 'CONTACT_INFO_UPDATED',
  UNAUTHORIZED: 'UNAUTHORIZED',
};

export const SYSTEM_MESSAGE = {
  USER_NOT_FOUND: 'USER_NOT_FOUND',
  USER_HAS_NO_SHOPS: 'USER_HAS_NO_SHOPS',
  FREE_TRAIL_MESSAGE: 'try_for_free',
  ENTERPRISE: 'ENTERPRISE',
};
