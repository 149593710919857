import { superAdminOnly, superAdminAndShopOwner } from 'src/utils/authUtils';
import { ROUTES } from 'src/utils/routes';
import { faImage, faShirt, faShoePrints } from '@fortawesome/free-solid-svg-icons';
import { CREATE_SHOP_FLOW } from 'src/utils/shopUtils';

export const siteSettings = {
  name: 'test',
  description: '',
  logo: {
    url: '/logo.png',
    alt: 'test',
    href: '/',
    width: 128,
    height: 40,
  },
  primaryColor: 'bg-accent',
  defaultLanguage: 'en',
  author: {
    name: 'RedQ, Inc.',
    websiteUrl: 'https://redq.io',
    address: '',
  },
  headerLinks: [],
  crateShopFlow: {
    type: CREATE_SHOP_FLOW.SHORT,
  },
  authorizedLinks: [
    {
      href: ROUTES.SHOPS,
      labelTransKey: 'sidebar-nav-item-mySites',
    },
    {
      href: ROUTES.LOGOUT,
      labelTransKey: 'authorized-nav-item-logout',
    },
  ],
  currencyCode: 'USD',
  sidebarLinks: {
    shop: [
      {
        href: () => `${ROUTES.SHOPS}`,
        label: 'sidebar-nav-item-mySites',
        icon: 'DashboardIcon',
        itemPermissions: superAdminAndShopOwner,
      },
      {
        href: () => `${ROUTES.CATEGORIES}`,
        label: 'sidebar-nav-item-categories',
        icon: 'CategoriesIcon',
        itemPermissions: superAdminAndShopOwner,
        stepName: '.step4',
      },
      {
        href: () => `${ROUTES.ADDONS_CATEGORIES}`,
        label: 'sidebar-nav-item-addons',
        icon: 'CategoriesIcon',
        itemPermissions: superAdminAndShopOwner,
      },
      {
        href: () => `${ROUTES.PRODUCTS}`,
        label: 'sidebar-nav-item-products',
        icon: 'ProductsIcon',
        itemPermissions: superAdminAndShopOwner,
        stepName: '.step5',
      },
      {
        href: () => `${ROUTES.BRANDS}`,
        label: 'sidebar-nav-item-brands',
        icon: 'BrandIcon',
        itemPermissions: superAdminAndShopOwner,
      },
      {
        href: () => `${ROUTES.ORDERS}`,
        label: 'sidebar-nav-item-orders',
        icon: 'OrdersIcon',
        itemPermissions: superAdminAndShopOwner,
      },
      {
        href: () => `${ROUTES.COUPONS}`,
        label: 'sidebar-nav-item-coupons',
        icon: 'CouponsIcon',
        itemPermissions: superAdminAndShopOwner,
      },
      {
        href: () => `${ROUTES.SALES}`,
        label: 'sidebar-nav-item-sales',
        icon: 'SaleIcon',
        itemPermissions: superAdminAndShopOwner,
      },
      {
        href: () => `${ROUTES.ANALYTICS}`,
        label: 'sidebar-nav-item-analytics',
        icon: 'AnalyticsIcon',
        itemPermissions: superAdminAndShopOwner,
      },
      {
        href: () => `${ROUTES.CUSTOMERS}`,
        label: 'sidebar-nav-item-customers',
        icon: 'CustomersIcon',
        itemPermissions: superAdminAndShopOwner,
      },
      {
        href: () => `${ROUTES.SHIPPING}`,
        label: 'sidebar-nav-item-shipping',
        icon: 'ShippingsIcon',
        itemPermissions: superAdminAndShopOwner,
      },
      {
        href: () => `${ROUTES.SHOP_USERS}`,
        label: 'sidebar-nav-item-shop-users',
        icon: 'UsersIcon',
        itemPermissions: superAdminAndShopOwner,
      },
      {
        href: () => `${ROUTES.OPEN_ALERTS}`,
        label: 'sidebar-nav-item-alerts',
        icon: 'AlertsIcon',
        itemPermissions: superAdminAndShopOwner,
      },
      {
        href: () => `${ROUTES.TEMPLATE}`,
        label: 'sidebar-nav-item-template',
        icon: 'SettingsIcon',
        itemPermissions: superAdminAndShopOwner,
      },
      {
        href: () => `${ROUTES.CONTACT_US}`,
        label: 'sidebar-nav-item-contact-us',
        icon: 'ContactUsIcon',
        itemPermissions: superAdminAndShopOwner,
      },
      {
        href: () => `${ROUTES.PAYMENT_METHOD}`,
        label: 'sidebar-nav-item-payment-method',
        icon: 'OfficeGuyIcon',
        itemPermissions: superAdminOnly,
      },
      {
        href: () => `${ROUTES.PACKAGES}`,
        label: 'sidebar-nav-item-packages',
        icon: 'OrdersIcon',
        itemPermissions: superAdminOnly,
      },

      // {
      //   href: () => `${ROUTES.REFUNDS}`,
      //   label: "sidebar-nav-item-refunds",
      //   icon: "RefundsIcon",
      //   permissions: adminOwnerAndStaffOnly,
      // },
      // {
      //   href: (shop: string) => `/${shop}${ROUTES.STAFFS}`,
      //   label: "sidebar-nav-item-staffs",
      //   icon: "UsersIcon",
      //   permissions: adminAndOwnerOnly,
      // },
      // {
      //   href: (shop: string) => `/${shop}${ROUTES.WITHDRAWS}`,
      //   label: "sidebar-nav-item-withdraws",
      //   icon: "AttributeIcon",
      //   permissions: adminAndOwnerOnly,
      // },
      // {
      //   href: () => `${ROUTES.DOCUMENTATION}`,
      //   label: 'sidebar-nav-item-template',
      //   icon: 'SettingsIcon',
      //   itemPermissions: superAdminAndShopOwner,
      // },
    ],
  },
  productVariantsType: [
    {
      label: 'Cloths',
      value: 'Cloths',
      icon: faShirt,
    },
    {
      label: 'Shoes',
      value: 'Shoes',
      icon: faShoePrints,
    },
    {
      label: 'Picture',
      value: 'Picture',
      icon: faImage,
    },
  ],

  productCards: [
    {
      label: 'Helium',
      value: 'helium',
      img: '/image/card-helium.png',
    },
    {
      label: 'Neon',
      value: 'neon',
      img: '/image/card-neon.png',
    },
    {
      label: 'Argon',
      value: 'argon',
      img: '/image/card-argon.png',
    },
    {
      label: 'Krypton',
      value: 'krypton',
      img: '/image/card-krypton.png',
    },
    {
      label: 'Xenon',
      value: 'xenon',
      img: '/image/card-xenon.png',
    },
    {
      label: 'Radon',
      value: 'radon',
      img: '/image/card-radon.png',
    },
  ],
  siteLayoutCards: [
    {
      label: 'Classic',
      value: 'classic',
      img: '/image/layout-classic.png',
    },
    {
      label: 'Modern',
      value: 'modern',
      img: '/image/layout-modern.png',
    },
    {
      label: 'Standard',
      value: 'standard',
      img: '/image/layout-standard.png',
    },
    {
      label: 'Minimal',
      value: 'minimal',
      img: '/image/layout-minimal.png',
    },
  ],
  product: {
    placeholder: '/product.svg',
  },
  avatar: {
    placeholder: '/avatar-placeholder.svg',
  },
  order: {
    placeholder: '/order-placeholder.svg',
  },
  languageSidebarMenu: [
    {
      label: 'hebrew_lang',
      value: 'he',
    },
    {
      label: 'arabic_lan',
      value: 'ar',
    },
  ],
  salesOptions: [
    {
      label: 'sales-label-total-price-sale',
      value: 'TOTAL_PRICE_SALE',
    },
    {
      label: 'sales-label-total-category-sale',
      value: 'CATEGORY_SALE',
    },
  ],
  shopUsersRole: [
    {
      label: 'Admin',
      value: 'ADMIN',
    },
    {
      label: 'Manager',
      value: 'MANAGER',
    },
    {
      label: 'Employee',
      value: 'EMPLOYEE',
    },
  ],
  pluginsArray: [
    {
      id: 'CashOnDelivery',
      title: 'plugin_cash_on_delivery_title',
      description: 'plugin_cash_on_delivery_description',
      icon: 'faPeopleCarryBox',
    },
    {
      id: 'CreditPayment',
      title: 'credit_card_plugIn_title',
      description: 'credit_card_plugIn_description',
      icon: 'faCreditCardAlt',
    },
  ],
  languages: [
    {
      value: 'he',
      displayValue: ' עברית | ',
    },
    {
      value: 'ar',
      displayValue: ' العربيه ',
    },
  ],
};

// admin: [
//   {
//     href: ROUTES.SHOPS,
//     label: "sidebar-nav-item-mySites",
//     icon: "DashboardIcon",
//   },
//   {
//     href: ROUTES.SHOPS,
//     label: "sidebar-nav-item-shops",
//     icon: "ShopIcon",
//   },
//   {
//     href: ROUTES.ADMIN_MY_SHOPS,
//     label: "sidebar-nav-item-my-shops",
//     icon: "MyShopIcon",
//   },
//   {
//     href: ROUTES.PRODUCTS,
//     label: "sidebar-nav-item-products",
//     icon: "ProductsIcon",
//   }, {
//     href: ROUTES.PRODUCTS,
//     label: "sidebar-nav-item-shipping",
//     icon: "ProductsIcon",
//   },
//   {
//     href: ROUTES.ATTRIBUTES,
//     label: "sidebar-nav-item-attributes",
//     icon: "AttributeIcon",
//   },
//   {
//     href: ROUTES.GROUPS,
//     label: "sidebar-nav-item-groups",
//     icon: "TypesIcon",
//   },
//   {
//     href: ROUTES.CATEGORIES,
//     label: "sidebar-nav-item-categories",
//     icon: "CategoriesIcon",
//   },
// {
//   href: ROUTES.TAGS,
//   label: "sidebar-nav-item-tags",
//   icon: "TagIcon",
// },
// {
//   href: ROUTES.ORDERS,
//   label: "sidebar-nav-item-orders",
//   icon: "OrdersIcon",
// },
// {
//   href: ROUTES.ORDER_STATUS,
//   label: "sidebar-nav-item-order-status",
//   icon: "OrdersStatusIcon",
// },
// {
//   href: ROUTES.USERS,
//   label: "sidebar-nav-item-users",
//   icon: "UsersIcon",
// },
// {
//   href: ROUTES.COUPONS,
//   label: "sidebar-nav-item-coupons",
//   icon: "CouponsIcon",
// },
// {
//   href: ROUTES.TAXES,
//   label: "sidebar-nav-item-taxes",
//   icon: "TaxesIcon",
// },
// {
//   href: ROUTES.SHIPPINGS,
//   label: "sidebar-nav-item-shippings",
//   icon: "ShippingsIcon",
// },
// {
//   href: ROUTES.WITHDRAWS,
//   label: "sidebar-nav-item-withdraws",
//   icon: "WithdrawIcon",
// },
// {
//   href: ROUTES.REFUNDS,
//   label: "sidebar-nav-item-refunds",
//   icon: "RefundsIcon",
// },
// {
//   href: ROUTES.SETTINGS,
//   label: "sidebar-nav-item-settings",
//   icon: "SettingsIcon",
// },
// ],
