import { MouseEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../ui/button';

interface StepperProps {
  steps: Step[];
  submitButtonIsLoading?: boolean;
  updateSelectedStep: (val: number) => void;
  submitButtonText?: string;
}

interface Step {
  element: (stepProps: any) => JSX.Element;
  isNextDisabled: () => boolean | Promise<boolean>;
  onNextClick?: () => void;
  hasSubmitButton?: boolean;
}

const Stepper = ({
  steps,
  updateSelectedStep,
  submitButtonIsLoading = false,
  submitButtonText = 'submit-button',
}: StepperProps) => {
  const { t }: any = useTranslation<any>();
  const [currentStep, setCurrentStep] = useState<number>(0);

  const goNextStep = async (e: any) => {
    e.preventDefault();
    const res = await getIsNextDisable();
    if (res) {
      const step = steps[currentStep];
      step?.onNextClick?.();
      const nextStep = currentStep + 1;
      if (nextStep <= steps.length) {
        setCurrentStep(nextStep);
        updateSelectedStep(nextStep);
      }
    }
  };

  const getIsNextDisable = async () => {
    const step = steps[currentStep];
    return await step.isNextDisabled();
  };

  const getIsHasSubmitButton = () => {
    let hasSubmitButton = false;
    steps.forEach((step, i) => {
      if (currentStep === i) {
        hasSubmitButton = step?.hasSubmitButton || false;
      }
    });
    return !!hasSubmitButton;
  };
  const goPreviousStep = (e: MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    const previousStep = currentStep - 1;
    if (previousStep >= 0) {
      setCurrentStep(previousStep);
      updateSelectedStep(previousStep);
    }
  };

  const showSubmitButton = () => {
    return getIsHasSubmitButton() && currentStep === steps.length - 1;
  };

  return (
    <>
      <div className='lg:w-full mx-auto' key='stepper'>
        {steps.map((step, i) => (
          <step.element key={`step${i}`} />
        ))}
      </div>
      <div className='w-4/5 md:w-full sm:w-full fixed bottom-0 md:left-0 bg-white shadow-md smx:mb-[46px] smx:border-border-200'>
        <div className='px-5 md:px-8 py-4 flex items-center justify-between  '>
          <div className='flex p-2 h-full items-center justify-center focus:outline-none focus:text-accent lg:pr-[250px]'>
            <Button
              className='ms-4 md:ms-6'
              onClick={(e: any) => goPreviousStep(e)}
              variant='outline'
              disabled={currentStep === 0}
            >
              {t('previous-button')}
            </Button>
          </div>
          <div className='flex items-center space-s-8 smx:space-s-4'>
            {showSubmitButton() ? (
              <Button
                className='ms-4 md:ms-6'
                // onClick={() => goNextStep()}
                type='submit'
                loading={submitButtonIsLoading}
                disabled={!getIsNextDisable()}
              >
                {t(submitButtonText)}
              </Button>
            ) : (
              <Button
                className='ms-4 md:ms-6'
                onClick={(e) => goNextStep(e)}
                disabled={currentStep === steps.length - 1}
              >
                {t('next-button')}
              </Button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Stepper;
