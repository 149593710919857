import { SortOrder } from './generated';
import {
  LANGUAGE,
  ProductCardTypeEnum,
  SHOP_STATUS,
  ORDER_STATUS,
  SAVE_MODE,
  BusinessTypeEnum,
  SiteTemplateEnum,
} from './index';
import { Type, ProductType, Tag } from 'src/ts-types/generated';
import Pagination from 'src/components/ui/pagination';

export type ShopStatus = `${SHOP_STATUS}`;
export type ShopActions = `${SHOP_STATUS}`;
export type SaveMode = `${SAVE_MODE}`;
export type Language = `${LANGUAGE}`;
export type OrderStatus = `${ORDER_STATUS}`;
export type ImageType = {
  imageUrl: string;
  name: string;
};

export type RefundPayload = {
  orderId: string;
  amount: number;
  description: string;
};

export type AdminContactUsPayload = {
  emailAddress: string;
  name: string;
  phoneNumber: string;
  messageTitle: string;
  messageText: string;
};

export type WithdrawsQueryOptionsType = {
  text?: string;
  shop_id?: number;
  page?: number;
  parent?: number | null;
  limit?: number;
  orderBy?: string;
  sortedBy?: SortOrder;
};

export declare type VariantDetailsList = {
  id?: string;
  variantsOptionsCombineList?: VariantsOptionsCombineList[];
  price?: number;
  endDiscountDate?: string;
  countInStock?: number;
  nameWithTranslation?: TranslationObject[];
  mainImage?: string;
  variantMeta?: string;
  variantMetaText?: TranslationObject[];
  productCode?: string;
  moreImages?: any[];
  mainVariant?: boolean;
  discountPrice?: number;
  active?: boolean;
  variantAttributeValues?: any;
  variantTypeId?: any;
  thumbnailImage?: any;
  productMilli?: any;
  productSEO?: any;
};
export declare type VariantsOptionsCombineList = {
  optionNameId?: string;
  optionValueId?: string;
  optionValue?: TranslationObject[];
};

export declare type VariantAllOptions = {
  optionName?: VariantAllOptionsOptionName;
  optionsValues?: VariantAllOptionsOptionsValues;
};
export declare type VariantAllOptionsOptionsValues = {
  optionValueId?: string;
  valueWithTeanslations?: TranslationObject[];
  meta?: string;
  [key: string]: any;
};
export declare type VariantAllOptionsOptionName = {
  optionNameId?: string;
  valueWithTeanslations?: TranslationObject[];
  meta?: string;
  [key: string]: any;
};
export declare type Attribute = {
  optionName: string;
  optionNameId: string;
  values: AttributeValue[];
  [key: string]: any;
};

export declare type AttributeValue = {
  optionValue: string;
  optionValueId: string;
  [key: string]: any;
};

export interface PlansDetailsResponse extends APIResponse {
  data: PlanDetails[];
}

export type UpdatePlanDetails = {
  PackageTitleWithTranslations: TranslationObject[];
  packageDescriptionWithTranslations: TranslationObject[];
  active: boolean;
  durationMonths: number;
  maxNumberOfProducts: number;
  price: number;
  priceForOnePayment: number;
  packageId?: string;
};

export type PlanDetails = {
  packageDescription?: string;
  packageTitle?: string;
  active?: boolean;
  durationMonths?: number;
  maxNumberOfProducts?: number;
  packageId?: string;
  priceForOnePayment?: number;
  price: number;
};

export type TranslationObject = {
  value: string;
  Language?: string;
};

export type Promotion = {
  image?: string;
  action: string;
};

export interface Categories extends Pagination, APIResponse {
  data: Category[];
}

export interface AddOnsCategories extends Pagination, APIResponse {
  data: AddOnCategory[];
}

export interface Brands extends Pagination, APIResponse {
  data: Brand[];
}
export type AddOnCategory = {
  addOnCategoryId?: string;
  titleWithTeanslations?: TranslationObject[];
  maxAddonsToSelect?: number;
  minAddonsToSelect?: number;
  active?: boolean;
  addOnsList?: AddOn[];
};
export interface AddOn {
  addOnId?: string;
  nameWithTeanslations?: TranslationObject[];
  image?: string;
  price?: number;
  active?: boolean;
}

export type Category = {
  id?: string;
  categoryId?: string;
  categoryNameWithTeanslations?: TranslationObject[];
  brandName?: string;
  categoryTagIcons?: string;
  categoryImage?: string;
  parentId?: string;
  categoryIcon?: string;
  categorySEO?: string;
  orderNumber?: number;
  children?: Category[]; // Ensure this is correctly type
  active?: boolean;
  totalProducts?: number;
  name?: string;
};
export type Brand = {
  brandId: string;
  brandNameWithTeanslations?: TranslationObject[];
  brandImage?: string;
  topBrand?: boolean;
  mainCategory?: string;
  [key: string]: any;
};
export type Pagination = {
  pageNumber?: number;
  pageSize?: number;
  totalElements?: number;
};

export interface ProductsPagination extends Pagination {
  searchText?: string;
  categoryId?: string;

  productId?: string;
}
export interface Products extends Pagination, APIResponse {
  data: Product[];
}
export type ProductInfoWithTranslations = {
  language?: string;
  name?: string;
  shortDescription?: string;
  description?: string;
};
export interface ProductVariant {
  variantImage?: string;
  variantSalePrice?: number;
  variantQuantity?: number;
  variantStatus?: boolean;
  variantAttributeValues?: string;
  variantOptionValue?: any[];
  [key: string]: any;
}

export type AddOnsCategoriesID = {
  addonCategoryId?: string;
  minNumberOfAddons?: number;
};

export type DeviceTokenRequest = {
  userId?: string;
  deviceToken?: string;
};
export interface Product {
  productId?: string;
  productCode?: string;
  addOnsCategories?: AddOnsCategoriesID[];
  name?: TranslationObject[];
  description?: TranslationObject[];
  shortDescription?: TranslationObject[];
  productInfoWithTranslations?: ProductInfoWithTranslations[];
  price?: number;
  mainImage?: string;
  moreImages?: string[];
  brandId?: string;
  productMilli?: number;
  categoryId?: string;
  endDiscountDate?: string;
  countInStock?: number;
  categoryDiscountPrice?: number;
  categoryEndDiscountDate?: string;
  variantAllOptions?: VariantAllOptions[];
  variantDetailsList?: VariantDetailsList[];
  discountPrice?: number;
  productType?: ProductType;
  quantitySaleObject?: {
    quantityForSale: number;
    quantityToDiscount: number;
    expirationDate: string;
    saleDescription: string;
  };
  active?: boolean;
  topProduct?: boolean;
  hotProduct?: boolean;
  variantType?: string;
  productSEO?: string;
  [key: string]: any;
}
export type Register = {
  name: string;
  email: string;
  password: string;
};
export type LogIn = {
  accessToken: string;
  refreshToken: string;
  id: string;
};

export type OtpResponse = {
  valid: boolean;
};

export type UserDetails = {
  userName: string;
  birthDate: string;
  address: {
    country: string;
    city: string;
    streetName: string;
    homeNumber: number;
    floorNumber: number;
    postalNumber: number;
  };
  profilePictureUrl: string;
  gender: string;
  userId: string;
};
export interface PlanDetailsResponse extends APIResponse {
  data: PlanDetails;
}

export interface AdminContactUsResponse extends APIResponse {
  success?: boolean;
}

export type APIResponse = {
  isFetching?: boolean;
};

export interface ContactUsResponse extends APIResponse {
  data?: ContactUsPayload;
}

export interface ValidateDomainResponse extends APIResponse {
  validDomain?: boolean;
  suggestedDomain: string[];
}

export interface MyShopsResponse extends APIResponse {
  data: ShopDetailsType[];
}

export interface ActiveShopResponse extends APIResponse {
  data: ShopDetailsType;
}

export interface ShopAnalyticsResponse extends APIResponse {
  data?: ShopAnalytics;
}

export interface ParamsDetails {
  shopId: string;
  shopExpirationTime: Date;
  shopName: string;
  productId: string;
  variantId: string;
  productName: string;
  OrderId: string;
  image: string;
  dateTime: Date;
}
export interface SubAlertDetails {
  alertType: string;
  isAlreadySeen: boolean;
  paramsDetails: ParamsDetails;
}

export interface AlertDetails {
  alertId?: string;
  isAlreadySeen?: boolean;
  alertDetails?: SubAlertDetails;
}

export interface AllMyAlertsResponse extends APIResponse {
  numberOfUnseenAlerts?: number;
  data?: AlertDetails[];
}

export interface AuthUserResponse extends APIResponse {
  data?: UserDetails;
}

export interface ValidateOTPResponse extends APIResponse {
  data?: OtpResponse;
}

export type NotificationDetailsRequest = {
  message?: string;
};

export type ShopShopPaymentSettingsType = {
  supportsCash: boolean;
  supportsCreditCard: boolean;
  maxNumberOfInstallments: number;
  Officeguy?: {
    companyID: string;
    apiKey: string;
    sendInvoiceByEmail: boolean;
  };
  paypalCredentials?: {
    environment: string;
    merchantId: string;
    publicKey: string;
    privateKey: string;
  };
};

export type ShopAppearanceType = {
  layoutType: LayoutType;
  productCardType: string;
  headerColor: string;
  footerColor: string;
  siteBackground: string;
  primaryColor: string;
  secondaryColor: string;
  tertiaryColor: string;
  siteFontFamily: string;
};

export enum LayoutType {
  ELEGANCE_PRO = 'elegancePro',
  MODERN = 'Modern',
  STANDARD = 'standard',
  ClASSIC = 'classic',
  default = 'default',
}

export type ShopMediaType = {
  siteTitle?: TranslationObject[];
  siteSubtitle?: TranslationObject[];
  shopDescription?: TranslationObject[];
  logoUrl?: string;
  siteImages?: { siteMainImages: string[]; siteMainPageImages: string[] };
  promotionSection?: {
    promotions?: Promotion[];
    description?: TranslationObject[];
  };
};

export type ShopPolicyType = {
  termsAndCondition: {
    linkToFile: string;
    content: string;
  };
};

export type ShopPreferencesType = {
  settings?: {
    loginBySocialMedia: {
      googleAppId: string;
      facebookAppId: string;
    };
  };
  attributes?: {
    additionalProp1: string;
    additionalProp2: string;
    additionalProp3: string;
  };
  validateOtpNeeded: boolean;
  notificationsByWhatsapp: boolean;
  notificationToUserViaSMS: boolean;
  userWayAccountId: string;
  printSeetings?: PrintSettings;
};

export type ShopBusinessDetailsRequestType = {
  customerDetails?: CustomerDetails;
  companyDetails?: CompanyDetailsRequest;
  businessType: BusinessTypeEnum;
};

export type CompanyDetailsRequest = {
  companyName?: string;
  companyType?: string;
  phoneNumber?: number | null;
  companyNumber?: number;
};

export type ShopBusinessSettingsType = {
  printSettings?: PrintSettings;
  Officeguy?: OfficeGuy;
};

export type ShopDetailsType = {
  shopId?: string;
  customerDetails?: CustomerDetails;
  companyDetails?: CompanyDetails;
  cardDetails?: CardDetails;
  packageId?: string;
  layoutType?: LayoutType;
  userWayAccountId?: string;
  siteTitle?: any;
  siteSubtitle?: any;
  shopExpirationDate?: string;
  shopCurrentStatus?: ShopStatus;
  shopDescription?: any;
  siteImages?: { siteMainImages: string[]; siteMainPageImages: string[] };
  productCardType?: string;
  logoUrl?: string;
  website?: string;
  recurrence?: boolean;
  plugIns?: PlugInType[];
  withPackage?: boolean;
  Officeguy?: OfficeGuy;
  notificationToUserViaSMS?: boolean;
  headerColor?: string;
  footerColor?: string;
  siteBackground?: string;
  primaryColor?: string;
  secondaryColor?: string;
  tertiaryColor?: string;
  siteFontFamily?: string;
  promotionSection?: {
    promotions?: Promotion[];
    description?: TranslationObject[];
  };
  notificationsByWhatsapp?: boolean;
  validateOtpNeeded?: boolean;
  businessType?: BusinessTypeEnum;
  printSeetings?: PrintSettings;
};

export type PrintSettings = {
  printerId: string;
  supportOrderPrint: boolean;
};
export interface LastYearSalesHistory {
  mounth: number;
  year?: number;
  totalRevenue?: number;
}

export interface PlugInType {
  active: boolean;
  plugInId: string;
  plugInTitleWithTranslations: TranslationObject[];
  plugInDescriptionWithTranslations: TranslationObject[];
  icon?: any;
}

export interface ShopAnalytics extends APIResponse {
  totalRevenue?: number;
  todaysRevenue?: number;
  totalOrders?: number;
  totalPendingOrders?: number;
  saleHistoryLastYear?: LastYearSalesHistory[];
}

export interface ShopAnalytics extends APIResponse {
  saleHistoryLastYear?: LastYearSalesHistory[];
  totalActiveProducts?: number;
  totalInactiveProduct?: number;
  totalClients?: number;
  totalRevenue?: number;
  todaysRevenue?: number;
  totalOrders?: number;
  totalPendingOrders?: number;
}

export type CategoriesQueryOptionsType = {
  type?: string;
  text?: string;
  page?: number;
  parent?: number | null;
  limit?: number;
  orderBy?: string;
  sortedBy?: SortOrder;
};
export type TagsQueryOptionsType = {
  type?: string;
  text?: string;
  page?: number;
  parent?: number | null;
  limit?: number;
  orderBy?: string;
  sortedBy?: SortOrder;
};
export type UpdateShopPlugInPayload = {
  shopId: string;
  plugInId: string;
  status: boolean;
};
export type CreateShopInput = {
  shopId?: string;
  packageId?: string;
  customerDetails?: CustomerDetails;
  cardDetails?: CardDetails;
  companyDetails?: CompanyDetails;
  website?: string;
  recurrence?: boolean;
  [key: string]: any;
};

export type CreateAnonymousShopInput = {
  packageId?: string;
  businessType?: BusinessType;
  layoutType?: string;
  website?: string;
  companyDetails?: CompanyDetails;
};

export type AddOnCategoryInput = {
  addOnCategoryId?: string;
  titleWithTeanslations?: TranslationObject[];
  maxAddonsToSelect?: number;
  minAddonsToSelect?: number;
  active?: boolean;
  addOnsList?: AddOn[];
};

export type CreateCategoryInput = {
  categoryNameWithTeanslations?: TranslationObject[];
  categoryTagIcons?: string;
  categoryImage?: string;
  categoryIcon?: string;
  categoryId?: string;
  orderNumber?: number;
  parentCategoryId?: string;
  active?: boolean;
  categorySEO?: string;
};

export type BrandInput = {
  brandNameWithTeanslations: TranslationObject[];
  brandImage?: string;
  topBrand?: boolean;
  mainCategory?: string;
  brandId: string;
};

export type CustomerDetails = {
  customerName?: string;
  Phone?: string;
  emailAddress?: string;
  city?: string;
  streetAddress?: string;
  zipCode?: string;
  postalNumber?: string;
};

export type CardDetails = {
  creditCardNumber: string;
  creditCardExpirationMonthYear?: string;
  creditCardExpirationMonth?: number;
  creditCardExpirationYear?: number;
  creditCardCVV: string;
  creditCardCitizenID?: string;
  creditCardClientName?: string;
};
export type ShopsQueryOptionsType = {
  text?: string;
  page?: number;
  parent?: number | null;
  limit?: number;
  orderBy?: string;
  sortedBy?: SortOrder;
};
export type CompanyDetails = {
  companyName?: string;
  companyType?: string;
  phoneNumber?: number | null;
  companyNumber?: number;
  supportsCash?: boolean;
  maxNumberOfInstallments?: number;
  supportsCreditCard?: boolean;
  Officeguy?: OfficeGuy;
  shopId?: string;
  printSeetings?: PrintSettings;
};
export type OfficeGuy = {
  companyID: string;
  apiKey: string;
  sendInvoiceByEmail?: boolean;
  companyNumber?: number;
};

export type ProductsQueryOptionsType = {
  page?: number;
  shop_id?: number;
  text?: string;
  type?: string;
  category?: string;
  status?: string;
  limit?: number;
  orderBy?: string;
  sortedBy?: SortOrder;
};
export type TypesQueryOptionsType = {
  page?: number;
  text?: string;
  limit?: number;
  orderBy?: string;
  sortedBy?: SortOrder;
};
export type StaffsQueryOptionsType = {
  page?: number;
  shop_id?: number;
  limit?: number;
  orderBy?: string;
  sortedBy?: SortOrder;
};

export type QueryOptionsType = {
  page?: number;
  text?: string;
  shop_id?: number;
  limit?: number;
  orderBy?: string;
  sortedBy?: SortOrder;
};

export type ProductCardType = `${ProductCardTypeEnum}`;
export type BusinessType = `${BusinessTypeEnum}`;
export type SiteTemplate = `${SiteTemplateEnum}`;

type Variation = {
  formName: number;
};

export type ProductFormValues = {
  sku: string;
  name: TranslationObject[];
  productCode?: string;
  productSEO?: string;
  productMilli?: number;
  addOnsCategories?: AddOnsCategoriesID[];
  description?: TranslationObject[];
  shortDescription?: TranslationObject[];
  type: Type;
  productType: ProductType;
  unit?: string | number;
  price: number;
  discountPrice: number | undefined;
  categories: Category[];
  endDiscountDate?: string;
  tags: Tag[];
  inStock: boolean;
  isTaxable: boolean;
  image: any;
  gallery: any[];
  active: boolean;
  width: string;
  height: string;
  length: string;
  isVariation: boolean;
  variantDetailsList: VariantDetailsList[];
  productInfoWithTranslations?: ProductInfoWithTranslations[];
  variationOptions: Product['variation_options'];
  mainImage?: string;
  hotProduct?: boolean;
  topProduct?: boolean;
  outOfStock?: boolean;
  [key: string]: any;
};

export type VariantFormValues = {
  id?: string;
  active?: boolean;
  mainVariant?: boolean;
  productMilli?: number;
  nameWithTranslation: TranslationObject[];
  productCode?: string;
  productSEO?: string;
  price: number;
  countInStock?: number;
  discountPrice: number;
  mainImage?: string;
  moreImages?: string[];
  thumbnailImage?: string;
  endDiscountDate?: string;
  variantsOptionsCombineList?: VariantsOptionsCombineList[];
  variantMetaText?: TranslationObject[];
  variantMeta?: any;
  variantTypeId?: string;
};

export interface Order {
  orderId?: string;
  createdAt?: string;
  orderStatus?: OrderStatus;
  orderComments?: string;
  invoiceUrl?: string;
  items?: OrderItem[];
  cashMoney?: boolean;
  customerDetails?: CustomerDetails;
  paymentDetails?: PaymentDetails;
}

export interface ActiveOrderResponse extends APIResponse {
  data?: Order;
}

export interface OrderItem {
  productId: string;
  productCode?: string;
  name: string;
  orderedVariantsList: OrderedVariantsList[];
  totalPriceAfterAllDiscounts: number;
  description: number;
}

export interface PaymentDetails {
  paymentId: string;
  productPrice: number;
  couponDescount: number;
  saleMoneyToDiscount: number;
  shippingPrice: number;
  finalTotalPriceWithoutTax: number;
  finalTotalPriceWithTax: number;
  totalDiscount: number;
  tax: number;
}

export interface OrderedVariantsList {
  variantId: string;
  productCode: string;
  ProductCode: string;
  price: number;
  mainImage: string;
  quantity: number;
  unitPriceBeforeDiscount: number;
  discount: number;
  unitPriceAfterDiscount: number;
  variantsOptionsCombineList: VariantsOptionsCombineList[];
}

export interface Orders extends Pagination, APIResponse {
  data: Order[];
}

export interface SaleDetailsResponse extends APIResponse {
  data?: SaleDetails[];
}
export interface PluginResponse extends APIResponse {
  data?: PlugInType[];
}

export interface CreateShopResponse extends APIResponse {
  data: CreateShopDataResponse;
}
export interface ShopEmployeesResponse extends APIResponse {
  data?: EmployeeDetails[];
}

type CreateShopDataResponse = {
  shopId: string;
};
export interface SaleDetails {
  saleId: string;
  saleDetails: SaleSubDetails;
}

export interface SaleSubDetails {
  saleId?: string;
  saleType?: string;
  moneyToDiscount?: number;
  percentageToDiscount?: number;
  categoryId?: string;
  expirationDate?: string;
  saleDescription?: TranslationObject[];
  totalPriceSaleObject?: TotalPriceSaleObject;
}

export interface TotalPriceSaleObject {
  minPriceToGetSale: number;
}

export interface ShippingAdWithRulesResponse extends APIResponse {
  data?: ShippingAdWithRules;
}
export interface ShippingAdWithRules {
  shippingId?: string;
  active?: boolean;
  shippingPrice?: number;
  freeShippingWhenTotalIs?: number;
  pricePerDistance?: boolean;
  distancePriceList?: DistancePrice[];
  adMessage?: TranslationObject[];
}

export type DistancePrice = {
  upToDistance: number;
  price: number;
  moreInfo: string;
};

export interface CouponDetails {
  couponName?: string;
  moneyToDiscount?: number;
  percentToDiscount?: number;
  percentageToDiscount?: number;
  couponRule?: CouponRule;
  avilableCoupons?: number;
  expirationDate?: string;
}

export interface EmployeeDetails {
  userId?: string;
  employeeMobile: string;
  employeeEmail: string;
  password: string;
  employeeRole: string;
}

export interface CouponRule {
  minPriceToUseCoupon: number;
}

export interface CouponsResponse extends APIResponse {
  data?: CouponDetails[];
}

export interface RegisterDomainPayload {
  domainName?: string;
  domainPrefix?: string;
  branch?: string;
}

export interface ContactUsPayload {
  emailAddress?: string;
  phoneNumber?: string;
  secondNumber?: string;
  thirdNumber?: string;
  openingTimes?: [
    {
      weekDay?: string;
      startTime?: string;
      endTime?: string;
    },
  ];
  address?: {
    city?: string;
    streetAddress?: string;
    waze?: string;
    shopImage?: string;
    longitude?: string;
    latitude?: string;
  };
  aboutUs?: string;
  whatsUp?: string;
  followUs?: {
    facebook?: string;
    twitter?: string;
    instagram?: string;
    youtube?: string;
  };
}
