export const ROUTES = {
  ENTRY: '/',
  UNAUTHORIZED: '/unauthorized',
  SHOPS: '/shops',
  // SETTINGS: '/shops/settings',
  ACTIVE_SHOP: '/shops/activeShop/',

  TEMPLATE: '/shops/shop/template',
  EDIT_CREATE_SALE: '/shops/shop/edit-create-sale/',
  EDIT_CREATE_COUPON: '/shops/shop/edit-create-coupon/',
  OPEN_ALERTS: '/shops/shop/alerts/',
  // PICK_MY_PLAN: '/pickMyPlan/',
  EDIT_SHOP: '/shops/shop/edit/',
  CREATE_SHOP: '/shops/shop/create-shop/',
  CATEGORIES: '/shops/shop/categories/',
  CREATE_CATEGORY: '/shops/shop/create-category/',
  EDIT_CATEGORY: '/shops/shop/edit-category/',
  ADDONS_CATEGORIES: '/shops/shop/addons-categories/',
  CREATE_CATEGORY_ADDONS: '/shops/shop/create-category-addons/',
  EDIT_CATEGORY_ADDONS: '/shops/shop/edit-category-addons/',
  ADDONS: '/shops/shop/addons-categories/addons',
  CREATE_ADDONS: '/shops/shop/create-category-addons/create-addons',
  EDIT_ADDONS: '/shops/shop/edit-category-addons/edit-addons',
  CREATE_BRAND: '/shops/shop/create-brand/',
  EDIT_BRAND: '/shops/shop/edit-brand/',
  BRANDS: '/shops/shop/brands/',
  ANALYTICS: '/shops/shop/analytics/',
  ORDER_STATUS: '/shops/shop/order-status/',
  ORDERS: '/shops/shop/orders/',
  PRODUCTS: '/shops/shop/products/',
  EDIT_PRODUCT: '/shops/shop/products/edit-product/',
  // CREATE_PRODUCT: '/shops/shop/create-product/',
  SHIPPING: '/shops/shop/shipping/',
  SALES: '/shops/shop/sales/',
  CUSTOMERS: '/shops/shop/customers/',
  COUPONS: '/shops/shop/coupons/',
  LOGOUT: '/shops/shop/logout/',
  SHOP_USERS: '/shops/shop/shopUsers/',
  EDIT_SHOP_EMPLOYEE: '/shops/shop/edit-create-user/',
  PACKAGES: '/shops/shop/packages/',
  PLUGINS: '/shops/shop/plugins/',
  EDIT_CREATE_PLUGIN: '/shops/shop/edit-create-plugin/',
  PAYMENT_METHOD: '/shops/shop/paymentmethod/',
  CONTACT_US: '/shops/shop/contactus/',
  IMAGES_UPLOADER: '/imagesUploader',

  CHOSE_PLAN: '/chose-plan',
  LOGIN: '/login',
  ANONY_CREATE_SHOP: '/acrShop/',
  ANONY_CREATE_SHOP_LAST_STEP: '/acrShop/details',
  PLANS: '/plans',
  USERS: '/users',
  TAXES: '/taxes',
  SHIPPINGS: '/shippings',
  STORE_SETTINGS: '/vendor/settings',
  STORE_KEEPER: '/vendor/store_keepers',
  ATTRIBUTES: '/attributes',
  ATTRIBUTE_VALUES: '/attribute-values',
  GROUPS: '/groups',
  TAGS: '/tags',
  WITHDRAWS: '/withdraws',
  PROFILE_UPDATE: '/profile-update',
  STAFFS: '/staffs',
  REFUNDS: '/refunds',
  REGISTER: '/register',
  TERMS_AND_CONDITION: '/termsandcondition',
  PRIVACY_POLICY: '/privacypolicy',
  DOCUMENTATION: '/documentation',
  ADMIN_CONTACT_US: '/contactUs',
};
