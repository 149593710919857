import * as yup from 'yup';

export const businessDetailsModalValidation = yup.object().shape({
  companyDetails: yup.object().shape({
    companyName: yup.string().required('error-companyName-required'),
    companyType: yup.string().required('error-companyType-required'),
    phoneNumber: yup
      .string()
      .nullable(true)
      .required('required')
      .matches(/^0(5[^7]|[2-4]|[8-9]|7[0-9])[0-9]{7}$/, 'error-phone-required'),
    companyNumber: yup
      .string()
      .nullable(true)
      .test('len', 'error-companyId', (val) => val?.length === 9),
  }),
  customerDetails: yup.object().shape({
    emailAddress: yup.string().email('error-submit-email').required('required'),
    Phone: yup
      .string()
      .nullable(true)
      .required('required')
      .matches(/^0(5[^7]|[2-4]|[8-9]|7[0-9])[0-9]{7}$/, 'error-phone-required'),
  }),
});
