import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useAppSelector, useAppDispatch } from 'src/utils/reduxHooks';
import {
  ActiveShopResponse,
  ShopDetailsType,
  ShopPreferencesType,
} from 'src/ts-types/custom.types';
import Card from 'src/components/common/card';
import Input from 'src/components/ui/input';
import { RootState } from 'src/store';
import Button from 'src/components/ui/button';
import { getActiveShopDetails, updateShopPreferencesDetails } from 'src/store/shop/shopsAPIActions';
import { toast } from 'react-toastify';
import { TOAST_MESSAGE } from 'src/utils/constants';
import Loader from 'src/components/ui/loader/loader';
import SwitchInput from 'src/components/ui/switch-input';

const ShopSettingsDetails = () => {
  const { t }: any = useTranslation();
  const activeShop = useAppSelector(
    (state: RootState) => state.shop.shopDetails?.activeShopDetailsResponse,
  ) as ShopDetailsType;

  const { notificationsByWhatsapp } = activeShop;
  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState<boolean>(false);

  const { data } = activeShop as ActiveShopResponse;

  const [getnotificationToUserViaSMS, setGeNotificationToUserViaSMS] = useState<boolean>(
    data?.notificationToUserViaSMS ?? false,
  );
  const [getButOtpVerification, setButOtpVerification] = useState<boolean>(
    data?.validateOtpNeeded ?? false,
  );
  const [supportOrderPrint, setSupportOrderPrint] = useState<boolean>(
    data?.companyDetails?.printSeetings?.supportOrderPrint ?? false,
  );

  const [getNotificationsByWhatsapp, setGetNotificationsByWhatsapp] = useState<boolean>(
    data.notificationsByWhatsapp || false,
  );

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<ShopPreferencesType>({
    mode: 'onBlur',
    defaultValues: {
      validateOtpNeeded: data.validateOtpNeeded ?? false,
      notificationsByWhatsapp: data.notificationsByWhatsapp,
      notificationToUserViaSMS: data.notificationToUserViaSMS,
      userWayAccountId: data.userWayAccountId,
      printSeetings: data.printSeetings,
    },
  });

  useEffect(() => {
    if (data) {
      setValue('notificationsByWhatsapp', data.notificationsByWhatsapp ?? false);
      setValue('notificationToUserViaSMS', data.notificationToUserViaSMS ?? false);
      setValue('validateOtpNeeded', data.validateOtpNeeded ?? false);
      setValue(
        'printSeetings.supportOrderPrint',
        data.companyDetails?.printSeetings?.supportOrderPrint ?? false,
      );

      setGetNotificationsByWhatsapp(data.notificationsByWhatsapp ?? false);
      setGeNotificationToUserViaSMS(data.notificationToUserViaSMS ?? false);
      setButOtpVerification(data.validateOtpNeeded ?? false);
      setSupportOrderPrint(data.companyDetails?.printSeetings?.supportOrderPrint ?? false);
    }
  }, [data, setValue]);

  const handleWhatsAppNotificationChanged = (val: any) => {
    setValue('notificationsByWhatsapp', Boolean(val));
    setGetNotificationsByWhatsapp(Boolean(val));
  };

  const handleNotificationToUserViaSMSChanged = (val: any) => {
    setValue('notificationToUserViaSMS', Boolean(val));
    setGeNotificationToUserViaSMS(Boolean(val));
  };
  const handleValidateOtpNeededChanged = (val: any) => {
    setValue('validateOtpNeeded', Boolean(val));
    setButOtpVerification(Boolean(val));
  };
  const handleSupportPrinterChanged = (val: any) => {
    setValue('printSeetings.supportOrderPrint', Boolean(val));
    setSupportOrderPrint(Boolean(val));
  };

  const onSubmit = (values: ShopPreferencesType) => {
    setLoading(true);
    const payload: ShopPreferencesType = {
      validateOtpNeeded: values.validateOtpNeeded,
      notificationsByWhatsapp: values.notificationsByWhatsapp ?? false,
      notificationToUserViaSMS: values.notificationToUserViaSMS ?? false,
      userWayAccountId: values.userWayAccountId,
      printSeetings: {
        printerId: values.printSeetings?.printerId ?? '',
        supportOrderPrint: values.printSeetings?.supportOrderPrint ?? false,
      },
    };
    dispatch(updateShopPreferencesDetails(payload))
      .then(() => {
        toast.success(t(TOAST_MESSAGE.COMPANY_INFO_UPDATED_SUCCESSFULLY));
        dispatch(getActiveShopDetails());
        setLoading(false);
      })
      .catch((err: any) => {
        if (err.response.data.status === 401) {
          toast.error(t(TOAST_MESSAGE.UNAUTHORIZED));
        } else {
          toast.error(t(TOAST_MESSAGE.ERROR_TOAST));
        }

        setLoading(false);
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {loading && (
        <div className='h-16 flex items-center mt-2 ms-2'>
          <Loader simple={true} className='w-6 h-6' />
        </div>
      )}
      <Card
        className='flex flex-col mb-4'
        title={t('site-settings-details')}
        titleClassName='text-lg font-semibold text-heading'
      >
        <div className=''>
          <SwitchInput
            className='pt-2'
            label={t('input-label-get-notification-by-whatsapp')}
            onChange={(val) => handleWhatsAppNotificationChanged(val)}
            name='status'
            defaultValue={getNotificationsByWhatsapp}
            value={getNotificationsByWhatsapp}
          />
        </div>
        <div className=''>
          <SwitchInput
            className='pt-2'
            label={t('input-label-get-notification-by-SMS')}
            onChange={(val) => handleNotificationToUserViaSMSChanged(val)}
            name='status'
            defaultValue={getnotificationToUserViaSMS}
            value={getnotificationToUserViaSMS}
          />
        </div>
        <div className=''>
          <SwitchInput
            className='pt-2'
            label={t('input-label-set-otp-buy-verification')}
            onChange={(val) => handleValidateOtpNeededChanged(val)}
            name='validateOtpNeeded'
            defaultValue={getButOtpVerification}
            value={getButOtpVerification}
          />
        </div>
        <div className='pt-4'>
          <div className='pt-4'>
            <SwitchInput
              className='pt-2'
              label={t('input-label-send-supportPrintOrder')}
              onChange={(val) => handleSupportPrinterChanged(val)}
              name='supportOrderPrint'
              defaultValue={supportOrderPrint}
              value={supportOrderPrint}
            />
            <Input
              label={t('input-label-printer-id')}
              {...register('printSeetings.printerId')}
              type='text'
              name='printerId'
              variant='outline'
              className='mb-4'
              error={t(errors?.printSeetings?.printerId?.message)}
            />
          </div>
          <Input
            label={t('input-label-user-way')}
            {...register('userWayAccountId')}
            type='text'
            name='printerId'
            variant='outline'
            className='mb-4'
            error={t(errors?.userWayAccountId?.message)}
          />
        </div>
        <Button type='submit' className='ml-5 mt-5'>
          {t('button-label-update')}
        </Button>
      </Card>
    </form>
  );
};

export default ShopSettingsDetails;
