import { useState, useEffect, useRef } from 'react';
import { useModalAction, useModalState } from 'src/components/ui/modal/modal.context';
import { useAppDispatch } from 'src/utils/reduxHooks';
import Button from '../ui/button';
import { useTranslation } from 'react-i18next';
import Input from '../ui/input';
import { getAllProducts, updateProduct } from 'src/store/shop/shopsAPIActions';
import { toast } from 'react-toastify';
import { TOAST_MESSAGE } from 'src/utils/constants';
import { Product, VariantDetailsList } from 'src/ts-types/custom.types';
import { getTranslatedProductItem } from 'src/common';

const UpdateProductPriceView = () => {
  const { data } = useModalState();
  const { t }: any = useTranslation();
  const { product, priceWithoutFormat, searchTerm, setIsLoading } = data as any;
  const [editablePrice, setEditablePrice] = useState(priceWithoutFormat);
  const { closeModal } = useModalAction();
  const dispatch = useAppDispatch();
  const { productInfoWithTranslations = [], productCode } = product as Product;
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  async function handleUpdatePrice() {
    setIsLoading(true);
    const { page: _page = 1 } = searchTerm ?? {};
    const updatedVariantDetailsList = product.variantDetailsList.map(
      (variant: VariantDetailsList, index: number) =>
        index === 0 ? { ...variant, price: editablePrice } : variant,
    );
    dispatch(
      updateProduct({
        ...product,
        price: editablePrice,
        variantDetailsList: updatedVariantDetailsList,
      }),
    )
      .then(() => {
        toast.success(t(TOAST_MESSAGE.SUCCESS_TOAST));
        dispatch(
          getAllProducts({ pageNumber: _page }, searchTerm ?? { categoryId: '', text: '' }, false),
        ).then((res: any) => {
          setIsLoading(false);
        });
      })
      .catch(() => {
        toast.error(t(TOAST_MESSAGE.ERROR_TOAST));
        setIsLoading(false);
      });
    closeModal();
  }
  return (
    <>
      <div className='p-4 pb-6 bg-light m-auto max-w-sm w-full rounded-md md:rounded-xl sm:w-[24rem]'>
        <div className='w-full h-full text-center'>
          <div className='flex h-full flex-col justify-between'>
            <p className='text-heading text-xl font-bold mt-4'>
              {getTranslatedProductItem(productInfoWithTranslations).name} - {productCode}
            </p>
            <div>
              <Input
                label={`${t('input-label-price')}`}
                required
                variant='outline'
                onChange={(e) => setEditablePrice(Number(e.target.value))}
                className='lg:w-2/3 step7prod'
                containerClassName='ml-6'
                name={''}
                value={editablePrice}
                ref={inputRef}
              />
            </div>
            <div className='flex items-center justify-between space-s-2 w-full mt-8'>
              <div className='w-1/2'>
                <Button
                  onClick={handleUpdatePrice}
                  variant='custom'
                  className='w-full py-2 px-4 focus:outline-none text-light transition ease-in duration-200 text-center text-base font-semibold rounded shadow-md bg-accent hover:bg-accent-hover focus:bg-accent-hover'
                >
                  {t('update')}
                </Button>
              </div>
              <div className='w-1/2'>
                <Button
                  onClick={() => closeModal()}
                  variant='custom'
                  className='w-full py-2 px-4 focus:outline-none text-light transition ease-in duration-200 text-center text-base font-semibold rounded shadow-md bg-red-600 hover:bg-red-700 focus:bg-red-700'
                >
                  {t('button-cancel')}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UpdateProductPriceView;
