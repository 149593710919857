import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBriefcase, faWrench, faFillDrip } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { useTranslation } from 'react-i18next';

type TabsProps = {
  step: number;
  setStep: (step: number) => void;
};

const Tabs: React.FC<TabsProps> = ({ step, setStep }: TabsProps) => {
  const { t }: any = useTranslation();

  const tabItems = [
    { icon: faBriefcase, label: t('SITE_TEMPLATE_BUSINESS_DETAILS'), step: 0 },
    { icon: faWrench, label: t('SITE_TEMPLATE'), step: 1 },
    { icon: faFillDrip, label: t('SITE_DESIGN'), step: 2 },
    { icon: faFillDrip, label: t('SITE_TEMPLATE_SHOP_SETTINGS'), step: 3 },
  ];

  const activeClass =
    'text-accent-600 border-b-2 border-accent-600 rounded-t-lg active dark:text-accent-500 dark:border-accent-500 group';
  const regularClass =
    'border-b-2 border-transparent rounded-t-lg hover:text-accent-600 hover:border-accent-300 dark:hover:text-accent-300 group';

  return (
    <div className='fixed inset-x-0  bg-white md:ml-5 md:mr-5 rounded shadow mt-0 lg:right-[287px] smx:top-16 z-50'>
      <div className='items-center justify-between dark:border-accent-300 float-right '>
        <ul className='flex flex-wrap -mb-px text-sm font-medium text-center text-accent-500 dark:text-accent-400 '>
          {tabItems.map((item, index) => (
            <li key={index} className='mr-2'>
              <a
                href='#'
                className={`inline-flex p-4 ${step === item.step ? activeClass : regularClass}`}
                onClick={() => setStep(item.step)}
              >
                <FontAwesomeIcon
                  icon={item.icon as IconProp}
                  className='pt-1 pl-2'
                  style={{ color: '#23AD92' }}
                />
                {item.label}
              </a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Tabs;
