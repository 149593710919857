import React from 'react';

type MODAL_VIEWS =
  | 'DELETE_PRODUCT'
  | 'UPDATE_PRODUCT_PRICE'
  | 'DELETE_ADDONS_CATEGORY'
  | 'DELETE_VARIANT'
  | 'DELETE_BRAND'
  | 'PRODUCT_VARIANT_VIEW'
  | 'DELETE_TYPE'
  | 'DELETE_ATTRIBUTE'
  | 'DELETE_CATEGORY'
  | 'DELETE_EMPLOYEE'
  | 'DELETE_ORDER'
  | 'DELETE_COUPON'
  | 'DELETE_TAX'
  | 'DELETE_SHIPPING'
  | 'DELETE_ORDER_STATUS'
  | 'DELETE_TAG'
  | 'BAN_CUSTOMER'
  | 'SHOP_APPROVE_VIEW'
  | 'SHOP_DISAPPROVE_VIEW'
  | 'DELETE_STAFF'
  | 'ADD_WALLET_POINTS'
  | 'UPDATE_REFUND'
  | 'PDF_VIEW'
  | 'REFUND_IMAGE_POPOVER'
  | 'EXPORT_IMPORT_PRODUCT'
  | 'EXPORT_IMPORT_ATTRIBUTE'
  | 'DELETE_SHOP'
  | 'PAYMENT_MODAL'
  | 'DELETE_SALE'
  | 'SITE_SETTINGS_MODAL'
  | 'PACKAGE_DETAILS_MODAL'
  | 'DELETE_COUPON'
  | 'DELETE_PLUGIN'
  | 'IMAGE_CROP'
  | 'OTP_MODAL'
  | 'REFUND';

interface State {
  view?: MODAL_VIEWS;
  data?: any;
  showCloseButton?: boolean;
  isOpen: boolean;
  title?: string | JSX.Element;
}
type Action =
  | {
      type: 'open';
      view?: MODAL_VIEWS;
      payload?: any;
      title?: string | JSX.Element;
      showCloseButton?: boolean;
    }
  | { type: 'close' };

const initialState: State = {
  view: undefined,
  isOpen: false,
  showCloseButton: true,
  data: null,
  title: '',
};

function modalReducer(state: State, action: Action): State {
  switch (action.type) {
    case 'open':
      return {
        ...state,
        view: action.view,
        data: action.payload,
        showCloseButton: action.showCloseButton,
        title: action.title,
        isOpen: true,
      };
    case 'close':
      return {
        ...state,
        view: undefined,
        data: null,
        showCloseButton: true,
        title: '',
        isOpen: false,
      };
    default:
      throw new Error('Unknown Modal Action!');
  }
}

const ModalStateContext = React.createContext<State>(initialState);
ModalStateContext.displayName = 'ModalStateContext';
const ModalActionContext = React.createContext<React.Dispatch<Action> | undefined>(undefined);
ModalActionContext.displayName = 'ModalActionContext';

export const ModalProvider: React.FC<any> = ({ children }) => {
  const [state, dispatch] = React.useReducer(modalReducer, initialState);
  return (
    <ModalStateContext.Provider value={state}>
      <ModalActionContext.Provider value={dispatch}>{children}</ModalActionContext.Provider>
    </ModalStateContext.Provider>
  );
};

export function useModalState() {
  const context = React.useContext(ModalStateContext);
  if (context === undefined) {
    throw new Error('useModalState must be used within a ModalProvider');
  }
  return context;
}

export function useModalAction() {
  const dispatch = React.useContext(ModalActionContext);
  if (dispatch === undefined) {
    throw new Error('useModalAction must be used within a ModalProvider');
  }
  return {
    openModal(
      view?: MODAL_VIEWS,
      payload?: unknown,
      title?: string | JSX.Element,
      showCloseButton?: boolean,
    ) {
      dispatch({ type: 'open', view, payload, title, showCloseButton });
    },
    closeModal() {
      dispatch({ type: 'close' });
    },
  };
}
