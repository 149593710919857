import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAppSelector, useAppDispatch } from 'src/utils/reduxHooks';
import { ActiveShopResponse, ShopBusinessDetailsRequestType } from 'src/ts-types/custom.types';
import Card from 'src/components/common/card';
import Input from 'src/components/ui/input';
import { businessDetailsModalValidation } from 'src/components/shop/businessDetailsModalValidation';
import { RootState } from 'src/store';
import Button from 'src/components/ui/button';
import { getActiveShopDetails, updateShopBusinessDetails } from 'src/store/shop/shopsAPIActions';
import { toast } from 'react-toastify';
import { TOAST_MESSAGE } from 'src/utils/constants';
import Loader from 'src/components/ui/loader/loader';

const BusinessDetails = () => {
  const { t }: any = useTranslation();
  const activeShop = useAppSelector(
    (state: RootState) => state.shop.shopDetails?.activeShopDetailsResponse,
  );
  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState<boolean>(false);

  const { data } = activeShop as ActiveShopResponse;

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<ShopBusinessDetailsRequestType>({
    mode: 'onBlur',
    defaultValues: {
      customerDetails: data.customerDetails,
      companyDetails: data.companyDetails,
      businessType: data.businessType,
    },
    resolver: yupResolver(businessDetailsModalValidation),
  });

  useEffect(() => {
    setValue('companyDetails.companyType', 'Company');
  }, [setValue]);

  const onSubmit = (values: ShopBusinessDetailsRequestType) => {
    setLoading(true);
    dispatch(updateShopBusinessDetails({ ...values }))
      .then(() => {
        toast.success(t(TOAST_MESSAGE.COMPANY_INFO_UPDATED_SUCCESSFULLY));
        dispatch(getActiveShopDetails());
        setLoading(false);
      })
      .catch((err: any) => {
        if (err.response.data.status === 401) {
          toast.error(t(TOAST_MESSAGE.UNAUTHORIZED));
        } else {
          toast.error(t(TOAST_MESSAGE.ERROR_TOAST));
        }
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {loading && (
        <div className='h-16 flex items-center mt-2 ms-2'>
          <Loader simple={true} className='w-6 h-6' />
        </div>
      )}
      <Card
        className='flex flex-col mb-4 md:w-full'
        title={<h1 className='text-lg font-semibold text-heading'>{t('shop-company-details')}</h1>}
      >
        <div className='flex flex-col md:flex-row'>
          <Input
            containerClassName='md:ml-6'
            label={t('input-label-company-name')}
            {...register('companyDetails.companyName')}
            variant='outline'
            placeholder={t('input-label-company-name-placeholder')}
            error={t(errors.companyDetails?.companyName?.message)}
          />
          <Input
            label={t('input-cpmany-id')}
            {...register('companyDetails.companyNumber')}
            variant='outline'
            placeholder={t('input-cpmany-id')}
            error={t(errors.companyDetails?.companyNumber?.message)}
          />
        </div>
        <div className='flex flex-col md:flex-row'>
          <Input
            label={t('input-label-company-phone')}
            containerClassName='md:ml-6'
            {...register('companyDetails.phoneNumber')}
            variant='outline'
            placeholder={t('input-label-phone-placeholder')}
            error={t(errors.companyDetails?.phoneNumber?.message)}
          />
        </div>
        <h1 className='text-lg mt-8 font-semibold text-heading'>{t('shop-customer-details')}</h1>
        <div className='flex flex-col md:flex-row'>
          <Input
            containerClassName='md:ml-6'
            label={t('input-label-customer-name')}
            {...register('customerDetails.customerName')}
            variant='outline'
            placeholder={t('input-label-company-name-placeholder')}
            error={t(errors.customerDetails?.customerName?.message)}
          />
          <Input
            label={t('input-label-email')}
            {...register('customerDetails.emailAddress')}
            variant='outline'
            placeholder={t('input-label-email')}
            error={t(errors.customerDetails?.emailAddress?.message)}
          />
        </div>
        <div className='flex flex-col md:flex-row'>
          <Input
            label={t('input-label-customer-phone')}
            containerClassName='md:ml-6'
            {...register('customerDetails.Phone')}
            variant='outline'
            placeholder={t('input-label-phone-placeholder')}
            error={t(errors.customerDetails?.Phone?.message)}
          />
        </div>
        <Button type='submit' className='ml-5 mt-5'>
          {t('button-label-update')}
        </Button>
      </Card>
    </form>
  );
};

export default BusinessDetails;
